import { KeyValuePipe } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, EventEmitter, Input, Output, computed } from "@angular/core";
import {
   BadgeComponent,
   ClearFilterButtonComponent,
   DropdownButtonComponent,
   DropdownClearFilterItemComponent,
   DropdownDateRangePickerComponent,
   DropdownDividerComponent,
   DropdownItemComponent,
   DropdownTextItemComponent,
   IconComponent,
   LimbleHtmlDirective,
   PopoverDirective,
   SearchBoxComponent,
   UpsellPopover,
} from "@limblecmms/lim-ui";
import moment from "moment";
import type { Subscription } from "rxjs";
import type { Filter, FilterType } from "src/app/dashboards/filters/index";
import {
   DateRangeFilter,
   LocationFilter,
   TaskTypeFilter,
   UserFilter,
} from "src/app/dashboards/filters/index";
import { ManageLang } from "src/app/languages/services/manageLang";
import { ManageLocation } from "src/app/locations/services/manageLocation";
import { orderBy } from "src/app/shared/pipes/orderBy.pipe";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { assert } from "src/app/shared/utils/assert.utils";
import { TaskEntityType } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "filter-selector",
   templateUrl: "./filterSelector.component.html",
   styleUrls: ["./filterSelector.component.scss"],
   imports: [
      IconComponent,
      DropdownButtonComponent,
      DropdownTextItemComponent,
      DropdownDividerComponent,
      DropdownDateRangePickerComponent,
      DropdownClearFilterItemComponent,
      SearchBoxComponent,
      DropdownItemComponent,
      LimbleHtmlDirective,
      ClearFilterButtonComponent,
      KeyValuePipe,
      BadgeComponent,
      UpsellPopover,
      PopoverDirective,
   ],
})
export class FilterSelectorComponent implements OnInit, OnDestroy {
   @Input() filterTypes: Set<FilterType>;
   @Input() dashboardReportingLimit: number | undefined;
   @Input() taskDateAttribute: "checklistDueDate" | "checklistCompletedDate";
   @Output() readonly filterChange: EventEmitter<
      FilterSelectorComponent["activeFilters"]
   >;
   public TaskType = TaskEntityType;
   public activeFilters: Map<FilterType, Filter>;
   public dateRangeStart: Date | undefined;
   public dateRangeEnd: Date | undefined;
   public locations: Array<any>;
   public locationsDropdown: Array<any> | undefined;
   public searchLocations: string = "";
   public users: Array<any>;
   private locationWatchVarSub: Subscription | null;
   private usersSub: Subscription | null;

   private readonly manageObservables = inject(ManageObservables);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageLocation = inject(ManageLocation);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.activeFilters = new Map();
      this.filterChange = new EventEmitter();
      this.locations = [];
      this.users = [];
      this.locationWatchVarSub = null;
      this.usersSub = null;
      this.filterTypes = new Set();
      this.taskDateAttribute = "checklistDueDate";
   }

   public ngOnInit(): void {
      if (this.filterTypes.has("location")) {
         this.locationWatchVarSub = this.manageObservables.setSubscription(
            "locationWatchVar",
            () => {
               this.buildLocationsDropdown();
            },
         );
      }
      if (this.filterTypes.has("location")) {
         this.usersSub = this.manageObservables.setSubscription("users", () => {
            this.users = this.manageUser.getUsers();
         });
      }

      this.setDataLimitingDefaultDateFilter();
   }

   private setDataLimitingDefaultDateFilter(): void {
      if (!this.dashboardReportingLimit) {
         return;
      }

      const reportingLimitOptions = [30, 90];
      if (!reportingLimitOptions.includes(this.dashboardReportingLimit)) {
         return;
      }

      const oneMonthAgo: Date = moment().subtract(30, "days").toDate();
      const threeMonthsAgo: Date = moment().subtract(90, "days").toDate();

      const reportingLimitMapping: Record<number, { rangeStart: Date; rangeEnd: Date }> =
         {
            30: {
               rangeStart:
                  this.dateRangeStart === undefined ||
                  moment(this.dateRangeStart).isBefore(oneMonthAgo)
                     ? oneMonthAgo
                     : this.dateRangeStart,
               rangeEnd:
                  this.dateRangeEnd === undefined ||
                  moment(this.dateRangeEnd).isBefore(moment(this.dateRangeStart))
                     ? moment().toDate()
                     : this.dateRangeEnd,
            },
            90: {
               rangeStart:
                  this.dateRangeStart === undefined ||
                  moment(this.dateRangeStart).isBefore(threeMonthsAgo)
                     ? threeMonthsAgo
                     : this.dateRangeStart,
               rangeEnd:
                  this.dateRangeEnd === undefined ||
                  moment(this.dateRangeEnd).isBefore(moment(this.dateRangeStart))
                     ? moment().toDate()
                     : this.dateRangeEnd,
            },
         };

      const mappedLimit = reportingLimitMapping[this.dashboardReportingLimit];

      this.dateRangeStart = mappedLimit.rangeStart;
      this.dateRangeEnd = mappedLimit.rangeEnd;

      const date1 = moment(this.dateRangeStart);
      const date2 = moment(this.dateRangeEnd);
      this.setFilter(new DateRangeFilter(date1, date2, this.taskDateAttribute));
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([
         this.locationWatchVarSub,
         this.usersSub,
      ]);
   }

   public buildLocationsDropdown(): void {
      this.locations = orderBy(this.manageLocation.getLocations(), "locationName");
      this.locationsDropdown = this.manageLocation.getLocations();
      if (this.searchLocations.length > 0) {
         this.locationsDropdown = this.manageFilters.filterLocationsToSearch(
            this.locationsDropdown,
            this.searchLocations,
         );
      }
      assert(this.locationsDropdown !== undefined);
      this.locationsDropdown = orderBy(this.locationsDropdown, "locationNameWithRegions");
   }

   private setFilter(filter: Filter): void {
      this.activeFilters.set(filter.filterType, filter);
      this.filterChange.emit(this.activeFilters);
   }

   public clearFilter(filterType: FilterType) {
      if (this.dashboardReportingLimit) {
         return;
      }
      this.activeFilters.delete(filterType);
      this.filterChange.emit(this.activeFilters);
   }

   public setDateRangeLastXMonths(numMonths: number): void {
      const date1 = moment()
         .subtract(numMonths - 1, "months")
         .startOf("month");
      const date2 = moment().endOf("month");
      this.setFilter(new DateRangeFilter(date1, date2, this.taskDateAttribute));
   }

   public setDateRangeThisYear(): void {
      const date1 = moment().startOf("year");
      const date2 = moment().endOf("month");
      this.setFilter(new DateRangeFilter(date1, date2, this.taskDateAttribute));
   }

   public setDateRangeLastYear(): void {
      const date1 = moment().subtract(1, "year").startOf("year");
      const date2 = moment().startOf("year");
      this.setFilter(new DateRangeFilter(date1, date2, this.taskDateAttribute));
   }

   public setCustomDateRange(event: MouseEvent): void {
      this.setDataLimitingDefaultDateFilter();
      if (this.dateRangeStart === undefined || this.dateRangeEnd === undefined) {
         event.stopPropagation();
         return;
      }
      const moment1 = moment(this.dateRangeStart);
      const moment2 = moment(this.dateRangeEnd);
      if (moment1.isSameOrBefore(moment2)) {
         this.setFilter(new DateRangeFilter(moment1, moment2, this.taskDateAttribute));
         return;
      }
      this.setFilter(new DateRangeFilter(moment2, moment1, this.taskDateAttribute));
   }

   public setLocationFilter(location: {
      locationID: number;
      locationName: string;
   }): void {
      this.setFilter(
         new LocationFilter(Number(location.locationID), location.locationName),
      );
   }

   public setTaskTypeFilter(taskType: TaskEntityType): void {
      this.setFilter(new TaskTypeFilter(taskType));
   }

   public setUserFilter(user: {
      userID: number;
      userFirstName: string;
      userLastName: string;
   }): void {
      const userFullName = `${user.userFirstName} ${user.userLastName}`;
      this.setFilter(new UserFilter(Number(user.userID), userFullName));
   }
}
